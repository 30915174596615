<template>
  <b-list-group-item
    v-if="payments.length"
    class="d-flex justify-content-between align-items-center px-0"
  >
    <div>
      <div class="font-weight-bold">
        {{ $t("emails.common.onsitePayments", locale) }}
      </div>
      <div
        v-for="(payment, index) in payments"
        :key="`onsite-payment-${index}`"
      >
        <small>
          {{ getPaymentText(payment) }}
        </small>
      </div>
    </div>
    <b-badge pill variant="info">
      {{ $t("Programat", locale) }}
    </b-badge>
  </b-list-group-item>
</template>

<script>
import { BBadge, BListGroupItem } from "bootstrap-vue";

export default {
  components: {
    BBadge,
    BListGroupItem,
  },
  props: {
    payments: {
      type: Array,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  methods: {
    getPaymentText(payment) {
      if (!payment?.name) return null;
      if (!payment.price) return payment.name;
      return `${payment.name}: ${payment.price}`;
    },
  },
};
</script>
